.menu{
    display: flex;
    align-items: center;
    gap: 3rem;
    font-family: "Satoshi Variable";
    font-size: 18px;
    font-weight: 500;
    color: azure;
    cursor: pointer;
    a{
        text-decoration: none;
        color: white;
    }
}

@media screen and (max-width: 768px) {
    .menu{
        order: 2;
        gap: 2rem;
    }
}