.header{
    display: flex;
    justify-content: space-between;
    padding: 0 4rem;
    
    .logoHeader{
        height: 100px;
        background-repeat: no-repeat;
        cursor: pointer;
    }
}

@media screen and (max-width: 768px) {
    .header{
        padding: 2px;
        justify-content: space-around;
        flex-wrap: wrap;
        .logoHeader{
            height: 50px;
        }
    }
}