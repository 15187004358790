.introP{
    color: white;
    padding: 15rem;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

@media screen and (max-width: 768px) {
    .introP{
        padding: 2rem;
    }
}