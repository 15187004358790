.under{
    color: #fff;
    display: flex;
    justify-content: space-between;
    width: 100%;
    h1{
        font-family: "Dancing Script", cursive;
        font-size: 50px;
        display: flex;
        align-items: center;
    }
    .underBackground{
        background-image: url('../../assets/images/perfume.png');
        width: 430px;
        height: 770px;
    }
}
.localFace{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 14px 30px;
    align-items: center;
    gap: 10px;
    background-image: url("../../assets/images/lavtxuduxi.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    font-family: 'Playfair Display', serif;
    filter: brightness(0.8);
    .localFaceBox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.507);
        border-radius: 3rem;
        height: 60%;
        h1{
            font-size: 4rem;
        }
        p{
            font-size: 1.5rem;
        }
    }
}

.ourValues{
    width: 100%;
    display: flex;
    .ourValuesBox{
        display: flex;
        width: 50%;
        height: 70vh;
        background-image: url("../../assets/images/terevduxi.png");
        background-repeat: no-repeat;
    }
    .ourValuesBoxText{
        width: 50%;
        color: white;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 0 5rem;
        font-family: "Playfair Display", serif;
    }
}

@media screen and (max-width: 768px) {
    .under{
        flex-wrap: wrap;
        position: relative;
        height: 450px;
        .underBackground{
            width: 100%;
            height: 100%;
            z-index: 0;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
        h1{
            position: absolute;
            bottom: 0;
            font-size: 35px;
            display: flex;
            align-items: center;
            z-index: 1;
        }
        
    }
    .localFace{
        .localFaceBox{
            h1{
                font-size: 3rem;
            }
            p{
                font-size: 1rem;
            }
        }
    }
    .ourValues{
        align-items: center;
        flex-direction: column;
        .ourValuesBox{
            height: 0;
        }
        .ourValuesBoxText{
            width: 90%;
            padding: 0;
        }
    }
}