.rightTop{
    display: flex;
    align-items: center;
    gap:10px;

    div{
        width: 50px;
        height: 35px;
        background-repeat: no-repeat;
        cursor: pointer;
    }
    .search{
        background-image: url('./../../assets/images/MagnifyingGlass.png');
    }
    input{
        border-radius: 9px;
        height: 30px;
        text-indent: 10px;
        font-weight: 600;
        border-color: white;
        background: black;
        color: white;
    }
}